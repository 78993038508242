/* eslint-disable import/prefer-default-export */
import queryString from 'query-string';

export const GTM_ARGUS = {
  id: 'GTM-W5L3TFB',
};

export const DISABLE_LAYOUT_PATHS = [
  '/download?enter=app',
  '/download/?enter=app',
  '/policy?layout=no',
  '/policy/?layout=no',
  '/independent-appointment',
  '/student-center-login',
  '/app/download',
];

export const DISABLE_KAAMEL_PATHS = ['/experienceClassBooking', '/terms'];

export const EMAIL_REG = /^[\w-.]+@[\w-.]+\.[a-zA-Z]{2,}$/;
export const PHONE_REG = /^[0-9\\-]{4,15}$/;

export const REPORT_PAGE_NAME_OFFICIAL_WEBSITE = '官网';

// 中文首页
export const REPORT_PAGE_NAME_OFFICIAL_WEBSITE_CHINESE_INDEX = '官网_CHINESE_INDEX';
// 数学首页
export const REPORT_PAGE_NAME_OFFICIAL_WEBSITE_MATH_INDEX = '官网_MATH_INDEX';

export const LEAD_CHINESE_EN = 'b6711d4e-6de4-4465-a4bd-3dca3d3e8c88';
export const LEAD_CHINESE_ZH = '8ac58659-6601-4ba7-af9e-62346a7b06cd';
export const BLOG_URL = 'https://wp-more.wukongedu.net/blog';

export const DOWNLOAD_APP_CENTER_PAGE = 'https://wukongedu.net/wp/launch-app';

export const TOKEN = 'token';

export const REFRESH_TOKEN = 'refresh_token';
export const LAST_REFRESH_TIME = 'last_refresh_time';
export const TOKEN_VALID_DAY = 30;

export const EDU_URL = '.wukongedu.net';
export const SCH_URL = '.wukongsch.com';

export const WECHAT_QR_HOST = 'https://open.weixin.qq.com/connect/qrconnect';
export const WECHAT_APPID = process.env.NEXT_PUBLIC_WECHAT_APPID || 'wxf3acbfbbdef5ddcd';
export const AUTH_REDIRECT_URI = process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI || '';
export const POST_MESSAGE_ID = 'd3Vrb25nbXNn';
export const QR_CODE_WHATSAPP = 'https://diversion.salesmartly.com/split/whatsapp/e8hf3u';
export const CHINESE_COURSE_URL =
  'https://www.wukongsch.com/blog/zh/cognia-certifies-wukong-education-highest-score-global-number-one-post-22206/';

export const LOGIN_REPORT_L = '61be7d93-a749-482b-960f-93b35bfbc979';

// 登录方式
export const LOGIN_TYPE = 'login_type';

// 触发的pvid
export const TRIGGER_PVID = 'trigger_pvid';

// 触发的事件名称
export const TRIGGER_EVENT = 'login_triggerevent';

export const BASE_URL = 'https://www.wukongsch.com';

export const getLoginReportL = () => {
  const currentUrlQuery = queryString.parseUrl(window.location.href).query;
  // const other = queryString.parseUrl(window.location.href);
  return currentUrlQuery?.l || LOGIN_REPORT_L;
};
export const STUDENT_WEB_RETAIN = 'STUDENT_WEB_RETAIN';
export const STUDENT_MOBILE_RETAIN = 'STUDENT_MOBILE_RETAIN';

// 约课挽留弹窗埋点事件
export const BOOK_RETAINPOP_EXPOSE = 'O_Booking_RetainPop_Expose';
export const BOOK_RETAINPOP_SCAN_WHATSAPP = 'O_Booking_RetainPop_ScanWhatsApp';
export const BOOK_RETAINPOP_ADD_WHATSAPP = 'O_Booking_RetainPop_AddWhatsApp';
export const BOOK_RETAINPOP_ADD_WECHAT = 'O_Booking_RetainPop_AddWeChat';
// app跳转到应用商店下载地址
export const WUKONG_IOS_DOWNLOAD_URL = 'https://apps.apple.com/be/app/wukong-class-learning-online/id1574837622';
export const WUKONG_ANDRIOD_DOWNLOAD_URL =
  'https://play.google.com/store/apps/details?id=com.wukongacademy.studentportal';
