import React from 'react';
import Title from '@/components/Title';
import LeadButton from '@/components/LeadButton';
import cls from 'classnames';

import Image from 'next/image';

import { useTranslation } from 'next-i18next';

const config = [
  {
    img: '/images/home/honor-1.png',
    alt: 'edtech',
    id: 1,
  },
  {
    img: '/images/home/honor-2.png',
    alt: 'DIGITAL EDUCATION AWARDS',
    id: 2,
  },
  {
    img: '/images/home/honor-3.png',
    alt: 'FORTUNE',
    id: 3,
  },
  {
    img: '/images/home/honor-4.png',
    alt: 'Holon IQ',
    id: 4,
  },
  {
    img: '/images/home/honor-5.png',
    alt: 'PRESTIGE AWARDS',
    id: 5,
  },
  {
    img: '/images/home/honor-6.png',
    alt: 'EDTECH',
    id: 6,
  },
];

const Honor = () => {
  const { t, i18n } = useTranslation(['home']);

  return (
    <section
      className="container relative mt-24 text-center lg:mt-[280px] xl:mt-[420px] "
      data-expose-event="O_Home_NewExpose"
    >
      <div className="absolute -top-16 left-1/2 w-full translate-x-[-50%]  md:-top-20 lg:-top-52 xl:-top-60">
        <Image
          alt="WuKong"
          className="mx-auto"
          width={1400}
          height={635}
          src="/images/home/honorbg.png"
          quality={100}
          priority
        />
      </div>
      <div className="relative z-10 text-center">
        <div className=" maxModuleWidth rounded-[10px] bg-white px-3 py-6 shadow-wk1  lg:rounded-5xl lg:px-8 lg:py-15 ">
          <Image
            alt="WuKong"
            className=" absolute right-[70px] top-[-60px] hidden h-[188px]  w-[188px] lg:block xl:-top-14 xl:right-60"
            width={188}
            height={188}
            src="/images/home/honor-icon.png"
            // quality={100}
          />
          <Image
            className=" m-auto  h-[60px] w-[206px] lg:h-32 lg:w-[440px] xl:h-40 xl:w-[550px]"
            alt={t('品牌荣誉-alt')}
            width={550}
            height={160}
            src="/images/home/honor-aognia.png"
          />
          <div className={cls(i18n.language === 'en' && 'flex flex-col-reverse')}>
            <Title
              className={cls(
                'mt-3 lg:mt-6 [&]:text-xl [&]:leading-normal [&]:lg:text-[28px] [&]:lg:leading-normal [&]:xl:text-[34px] [&]:xl:leading-normal ',
                i18n.language === 'en' && '[&]:mt-0 [&]:lg:mt-3',
              )}
              innerHTML
              theme="colorful"
            >
              {t('品牌荣誉-标题')}
            </Title>
            <p
              className={cls(
                'mt-5 text-xs text-wk-words-5 lg:mt-4 lg:text-base xl:text-xl',
                i18n.language === 'en' && '[&]:mt-3 [&]:lg:mt-6',
              )}
            >
              {t('品牌荣誉-副标题')}
            </p>
          </div>
        </div>
        <h3
          className="mt-6 text-xs lg:mt-15 lg:text-xl xl:mt-20 xl:text-[28px] [&_strong]:font-black  [&_strong]:text-wk-words-1 "
          dangerouslySetInnerHTML={{ __html: t('品牌荣誉-副标题2') }}
        />
        <div className="mx-auto mt-6  grid grid-cols-3 justify-items-center gap-3 lg:mt-15 lg:max-w-[740px] lg:gap-x-[52px] lg:gap-y-8 xl:mt-20 xl:max-w-full xl:grid-cols-6 xl:gap-6">
          {config.map((item) => (
            <div key={item.id} className="relative">
              <Image className=" object-cover" src={item.img} alt={item.alt} width={212} height={78} />
            </div>
          ))}
        </div>
        <div>
          <LeadButton
            target="_blank"
            theme="colorfulBorder"
            size="xl"
            className="mb-20 mt-6 lg:mb-40 lg:mt-15 xl:mb-50 xl:mt-20"
            traceName="O_Home_MediaModule_CTAClick"
            traceGa4Name="new_entry_HomePage_MediaModuleCTA"
          >
            {t('品牌荣誉-按钮')}
          </LeadButton>
        </div>
      </div>
    </section>
  );
};

export default Honor;
