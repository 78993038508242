import React from 'react';
import Title from '@/components/Title';
import Image from 'next/image';
import cls from 'classnames';

import { useTranslation } from 'next-i18next';
import LeadButton from '@/components/LeadButton';

const TeachingResearch = () => {
  const { t, i18n } = useTranslation(['home']);

  const config = [
    {
      title: '教学方法-item1-标题',
      info: '教学方法-item1-副标题',
      id: 1,
      classNameLi: 'lg:col-start-2 lg:col-end-3 lg:justify-self-center',
      classNameCon: '',
    },
    {
      title: '教学方法-item5-标题',
      info: '教学方法-item5-副标题',

      id: 2,
      classNameLi: 'lg:col-start-1  lg:col-end-2 lg:row-start-2 lg:row-end-3',
      classNameCon: '',
    },
    {
      title: '教学方法-item2-标题',
      info: '教学方法-item2-副标题',

      id: 3,
      classNameLi: 'lg:col-start-3 lg:col-end-4 lg:row-start-2 lg:row-end-3 lg:justify-self-end',
      classNameCon: '',
    },
    {
      title: '教学方法-item4-标题',
      info: '教学方法-item4-副标题',
      id: 4,
      classNameLi: `lg:col-start-1 lg:col-end-2 lg:row-start-3 lg:row-end-4 lg:justify-self-end ${i18n.language === 'en' ? 'lg:self-start' : 'lg:self-end'}`,
      classNameCon: 'relative  xl:-right-20 lg:-right-15 ',
    },
    {
      title: '教学方法-item3-标题',
      info: '教学方法-item3-副标题',
      id: 5,
      classNameLi: `lg:col-start-3 lg:col-end-4 lg:row-start-3 lg:row-end-4 ${i18n.language === 'en' ? 'lg:self-start' : 'lg:self-end'}`,
      classNameCon: 'relative xl:-left-20 lg:-left-15 ',
    },
  ];

  return (
    <section className="marginModelTop2  text-center" data-expose-event="O_Home_ClassroomFeaturesExpose">
      <div className="container">
        <Title className="" innerHTML theme="colorful">
          {t('教学方法-标题')}
        </Title>
      </div>

      <div className="lg:container">
        <div
          className={cls(
            // 原始
            // 'relative mx-auto mt-6 h-[386px] w-full lg:mt-12 lg:h-[718px] lg:w-[908px] xl:mt-15 xl:h-[792px] xl:w-[1162px]',
            // 中文
            // 'relative mx-auto mt-6 h-[386px] w-full lg:mt-12 lg:h-[718px] lg:w-[908px] xl:mt-15 xl:h-[685px] xl:w-[1083px]',
            // 英文
            'relative mx-auto mt-6 h-[386px] w-full lg:mt-12 lg:h-[534px] lg:w-[790px] xl:mt-15 xl:h-[684px] xl:w-[1084px]',
            i18n.language === 'en' && 'h-[472px] lg:h-[720px] lg:w-[790px] xl:h-[786px] xl:w-[1084px]',
          )}
        >
          {/* 背景 */}
          <div className=" absolute left-1/2 m-auto h-[308px] w-[320px] -translate-x-1/2 transform lg:top-1/2 lg:h-[346px] lg:w-[360px] lg:-translate-y-1/2 xl:h-[462px] xl:w-[480px]">
            <Image
              sizes="100vw"
              src="/images/home/teachingResearch-bg.png"
              alt="WuKong teachingResearch"
              width={480}
              height={462}
              className=" absolute h-auto w-full"
              quality={100}
            />
            <Image
              sizes="100vw"
              src="/images/home/teachingResearch-ip.png"
              alt="WuKong teachingResearch"
              width={480}
              height={462}
              className="absolute z-10 h-auto w-full"
            />
          </div>

          {/* 5个文案 */}
          <ul className=" hideScrollbar absolute bottom-0 left-1/2 z-20 flex w-full -translate-x-1/2 transform space-x-3 overflow-x-scroll px-5 pb-6 lg:top-1/2 lg:grid  lg:h-full lg:-translate-y-1/2 lg:grid-cols-3 lg:grid-rows-3 lg:gap-0 lg:space-x-0 lg:overflow-visible lg:px-0 lg:pb-0">
            {config.map((item) => (
              <li key={item.id} className={cls(item.classNameLi)}>
                <div
                  className={cls(
                    'relative h-full w-[210px] rounded-5xl border-2 border-white bg-[linear-gradient(184deg,_rgba(255,_83,_83,_0.03)_9%,rgba(109,_56,_251,_0.03)_62%,rgba(0,_157,_255,_0.03)_93%),_linear-gradient(180deg,_rgba(255,_255,_255,_0.4)_0%,rgba(255,_255,_255,_0.9)_100%)] px-4 py-6 shadow-wk1 backdrop-blur-md lg:h-auto  lg:w-60 xl:w-[330px] ',
                    item.classNameCon,
                  )}
                >
                  <h3 className="text-2xl font-black  lg:text-[28px]  xl:text-[34px]">{t(item.title)}</h3>
                  <h4 className="mt-2 text-sm leading-[22px] text-wk-words-5 lg:text-base lg:leading-6 xl:text-xl xl:leading-7 ">
                    {t(item.info)}
                  </h4>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <LeadButton
          theme="colorfulBorder"
          traceName="O_Home_CourseFeature_CTAClick"
          traceGa4Name="new_entry_HomePage_CourseFeature_CTA"
          size="xl"
          className=" lg:mt-10 xl:mt-15"
        >
          {t('教学方法-按钮')}
        </LeadButton>
      </div>
    </section>
  );
};

export default TeachingResearch;
