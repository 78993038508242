import { objToQuery } from '@/utils/url';
import { request, requestStu } from '@/request/index';
import { GetBlogListReq, GetBlogListRes } from './interface/common';
/**
 * 获取博客列表
 * @param params GetBlogListReq
 * @returns
 */
// export const getBlogList = (params: GetBlogListReq) => {
//   const queryString = objToQuery(params as unknown as { [key: string]: string });
//   return serverRequest<GetBlogListRes>(
//     `https://wp-more-test.wukongedu.net/blog/wp-json/wp/v2/wk_get_blog_cats_and_posts/${queryString}`,
//     {
//       method: 'get',
//       toJson: true,
//     },
//   );
// };
export const getBlogList = (params: GetBlogListReq) => {
  const queryString = objToQuery(params as unknown as { [key: string]: string });
  return request.get<GetBlogListRes[], GetBlogListRes[]>(
    `${process.env.NEXT_PUBLIC_BLOG}/blog/wp-json/wp/v2/wk_get_blog_cats_and_posts/${queryString}`,
  );
};

/**
 * 获取时间
 * @returns 时间戳
 */
export const getTimestamp = () => {
  return request.get<number, number>(`/public/timestamp`);
};

// 检查家庭日历是否订阅
export const checkSubscribe = () => {
  return requestStu
    .get<{ content: any }, { content: any }>(`api/student/calendar/check-subscription`)
    .then((res) => res?.content);
};
