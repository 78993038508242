import Cookie from 'js-cookie';
import CacheStorage from '@/utils/cacheStorage';
import {
  EDU_URL,
  LAST_REFRESH_TIME,
  LOGIN_TYPE,
  REFRESH_TOKEN,
  SCH_URL,
  TOKEN,
  TOKEN_VALID_DAY,
  TRIGGER_EVENT,
  TRIGGER_PVID,
} from '@/utils/constants';
// eslint-disable-next-line import/no-cycle
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import { isProduction } from './utils';

export const getTokenInfo = () => {
  return Cookie.get(TOKEN) || ''; // CacheStorage 会缓存上一次的token
};

export const setSameDomain = (token?: string) => {
  // 线上环境针对国内用户，格外提供一个 www-intl.wukongedu.net 的官网域名用于登录，然后跳转到 student.wukongedu.net 这个域名
  const domain = !isProduction() || window.location.hostname.includes('wukongedu.net') ? EDU_URL : SCH_URL;
  const finalToken = token || getTokenInfo();
  Cookie.set(TOKEN, finalToken, {
    domain,
    path: '/',
    expires: 31,
  });
  CacheStorage.setItem(TOKEN, finalToken); // api 请求会取 CacheStorage 里面的缓存
};

const setRefreshToken = (token: string) => {
  const domain = !isProduction() || window.location.hostname.includes('wukongedu.net') ? EDU_URL : SCH_URL;
  const cookieOptions = {
    domain,
    path: '/',
    expires: 31,
  };
  Cookie.set(REFRESH_TOKEN, token, cookieOptions);
  Cookie.set(LAST_REFRESH_TIME, `${dayjs().add(TOKEN_VALID_DAY, 'day').unix()}`, cookieOptions);
};

const removeCurrentDomainToken = () => {
  Cookie.remove(TOKEN);
};

export const setTokenInfo = (token: string, refreshToken?: string) => {
  // 删除当前域名下token，避免闪退
  removeCurrentDomainToken();
  // CacheStorage.setItem(TOKEN, token); // TODO: @lxd 非开发环境不需要缓存
  setSameDomain(token);
  if (refreshToken) {
    setRefreshToken(refreshToken);
  }
};

export const removeTokenInfo = () => {
  window?.Intercom?.('shutdown');
  const domain = isProduction() && !window.location.hostname.includes('wukongedu.net') ? SCH_URL : EDU_URL;
  CacheStorage.removeItem(TOKEN);
  CacheStorage.removeItem(REFRESH_TOKEN);
  CacheStorage.removeItem(LAST_REFRESH_TIME);
  [TOKEN, REFRESH_TOKEN, LAST_REFRESH_TIME].forEach((key) =>
    Cookie.remove(key, {
      domain,
      path: '/',
    }),
  );
};

export const hasTokenInfo = () => {
  const token = CacheStorage.getItem(TOKEN);

  return !!token;
};

/**
 * 设置登录方式，提供给学员中心使用
 *
 * @param {string} loginType
 */
export const setLoginType = (loginType: 'wechat' | 'google' | 'apple' | 'phone' | 'email') => {
  const domain = !isProduction() || window.location.hostname.includes('wukongedu.net') ? EDU_URL : SCH_URL;
  Cookie.set(LOGIN_TYPE, loginType, {
    domain,
    path: '/',
    expires: 31,
  });
};

/**
 * 设置触发pvid，提供给学员中心使用
 *
 * @param {string} pvid
 */
export const setTriggerPvid = (pvid: string) => {
  const domain = !isProduction() || window.location.hostname.includes('wukongedu.net') ? EDU_URL : SCH_URL;
  Cookie.set(TRIGGER_PVID, pvid, {
    domain,
    path: '/',
    expires: 31,
  });
};

/**
 * 设置触发事件名称，提供给学员中心使用
 *
 * @param {string} event
 */
export const setTriggerEvent = (event: string) => {
  const domain = !isProduction() || window.location.hostname.includes('wukongedu.net') ? EDU_URL : SCH_URL;
  Cookie.set(TRIGGER_EVENT, event, {
    domain,
    path: '/',
    expires: 31,
  });
};

interface UserData {
  studentId: string;
  code: string;
  associatedWeChat: boolean;
  studentLevel: number;
  associatedStudents: boolean;
  platform: string;
  status: string;
}
export interface TokenInfo {
  sub: string;
  authorities: string[];
  name: string;
  exp: number;
  phone: string;
  email: string;
  accountStatus: string;
  departments: string[];
  data: UserData;
}

export const getJwtTokenInfo = () => {
  const token = getTokenInfo();
  if (!token) return null;
  try {
    const tokenInfo = jwtDecode(token);
    if (tokenInfo) return tokenInfo as TokenInfo;
  } catch (e) {
    return null;
  }
  return null;
};
